<template>
    <section>
        <modal ref="CalificarPedidoAdmin" titulo="Calificar Pedido" icon="review" @guardar="accion_calificar">
            <div v-for="(i, c) in items" :key="c" class="row mx-0 px-5 py-2 border-bottom">
                <div class="col-4">
                    <p class="d-middle">
                        {{ i.nombre }}
                    </p>
                </div>
                <div class="col-6">
                    <el-rate
                    v-model="i.valor"
                    score-template="{value}"
                    />
                </div>
            </div>
            <hr class="my-3" />
            <div class="row mx-0 justify-center ">
                <div class="col-12">
                    <p>Comentario</p>
                    <el-input v-model="model.comentario" type="textarea" rows="3" maxlength="250" show-word-limit placeholder="Por favor dejamos tú comentario..." />
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import Calificaciones from "~/services/calificaciones";

export default {
    data(){
        return{
            items: [],
            id_pedido: null,
            model:{
                comentario:'',
                calificaciones:[]
            }
        }
    },
    methods: {
        toggle(id){
            this.id_pedido = id
            this.$refs.CalificarPedidoAdmin.toggle();
            this.get_items_calificar()
        },
        async get_items_calificar(){
            try {
                const { data } = await Calificaciones.get_items_calificar()
                this.items = data.items
            } catch (error){
                this.error_catch(error)
            }
        },
        async accion_calificar(){
            try {
                if(this.id_pedido === undefined || this.id_pedido == null || this.id_pedido == ''){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }

                this.model.calificaciones = this.items.filter(c => c.valor > 0)

                const { data } = await Calificaciones.calificar_pedido_admin(this.id_pedido,this.model)
                this.notificacion('Mensaje','Gracias por su calificación','success')
                this.$store.commit('pedidos/pedidos_historial_tendero/set_pedido', data.pedido)
                this.$refs.CalificarPedidoAdmin.toggle();

            } catch (e){
                console.error(e);
            }
        }
    }
}
</script>
<style lang="css" scoped>
.orange-star{
    color: #FF9D32;
}
</style>
